import { APP_INITIALIZER, forwardRef, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorSketchModule } from 'ngx-color/sketch';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardLayoutComponent } from './components/dashboard-layout/dashboard-layout.component';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material.module';
import { CommonPoolComponent } from './components/common-pool/common-pool.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConfigurationService } from './shared/_services/configuration.service';
import { environment } from 'src/environments/environment';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CreateDashboardLayoutComponent } from './components/create-dashboard-layout/create-dashboard-layout.component';
import * as echarts from 'echarts';
import 'echarts-gl';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgxEchartsModule } from 'ngx-echarts';
import { linechartComponent } from './shared/_components/charts/linechart/linechart.component';
import { areachartComponent } from './shared/_components/charts/areachart/areachart.component';
import { piechartComponent } from './shared/_components/charts/piechart/piechart.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ChartCommonPoolComponent } from './components/chart-common-pool/chart-common-pool.component';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
import { DashboardExportLayoutComponent } from './components/dashboard-export-layout/dashboard-export-layout.component';
import { AlertComponent } from './shared/_components/alert/alert.component';
import { GridsterModule } from 'angular-gridster2';
import { WidgetLayoutComponent } from './components/widget-layout/widget-layout.component';
import { BarchartComponent } from './shared/_components/charts/barchart/barchart.component';
import { TerrainchartComponent } from './shared/_components/charts/terrainchart/terrainchart.component';
import { ChartBuilderLayoutComponent } from './components/chart-builder-layout/chart-builder-layout.component';
import { BlocklyCommonPoolComponent } from './shared/_components/blockly-common-pool/blockly-common-pool.component';
import { SunburstchartComponent } from './shared/_components/charts/sunburstchart/sunburstchart.component';
import { BoxchartComponent } from './shared/_components/charts/boxchart/boxchart.component';
import { GaugechartComponent } from './shared/_components/charts/gaugechart/gaugechart.component';

const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.setConfig(environment.config);
  };
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    CommonPoolComponent,
    CreateDashboardLayoutComponent,
    linechartComponent,
    ChartCommonPoolComponent,
    areachartComponent,
    piechartComponent,
    ChartCommonPoolComponent,
    DashboardExportLayoutComponent,
    AlertComponent,
    WidgetLayoutComponent,
    ChartBuilderLayoutComponent,
    BarchartComponent,
    BlocklyCommonPoolComponent,
    SunburstchartComponent,
    TerrainchartComponent,
    BoxchartComponent,
    GaugechartComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    CodemirrorModule,
    GridsterModule,
    ColorSketchModule,
    NgxDaterangepickerMd.forRoot(),
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
    NgxEchartsModule.forRoot({ echarts }),
  ],
  exports: [
    linechartComponent,
    areachartComponent,
    piechartComponent,
    WidgetLayoutComponent,
    BarchartComponent,
    SunburstchartComponent,
    TerrainchartComponent,
    BoxchartComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [ConfigurationService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: forwardRef(() => JwtInterceptor), multi: true }

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
