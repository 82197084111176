import dayjs from "dayjs";
import { timeIntervalsEnumLabel } from "../enums/dateRange.enum";

export const GRAPH_CONFIGURATION_3D = {

    // X-axis config when date is on x-axis
    axisConfig_3D: {
        type: '',
        name: '',
        nameGap: 30,
        nameTextStyle: {
            fontFamily: 'Lato',
            fontSize: 13,
            lineHeight: 14,
            color: '#000000'
        },
        splitNumber: 5,
        axisLabel: {
            formatter: function (value:any) {
                return dayjs(value).format('hh:mm A');
              },
        },
        data: [],
        axisLine: {
            lineStyle: {
                color: '#000000'
            }
        },
        axisTick: {
            show: false,
        },
    },
   

    // Tooltip trigger configuration
    toolTipTriggerConfig: {
        renderMode: 'html',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        appendToBody: true,

        textStyle: {
            color: '#fff',
            fontFamily: 'LATO',
            fontSize: 11
        },
        padding: 10,
        extraCssText: 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); border-radius: 8px;',
        trigger: 'item'
    },

    // Axis line configuration
    axisLineConfig: {
        lineStyle: {
            color: '#CCCCCC'
        }
    },

    // grid configuration
    grid3DConfig: {
        containLabel: true,
        boxWidth: 100,
        boxDepth: 90,
        light: {
            main: {
                intensity: 1.2,
            },
            ambient: {
                intensity: 0.3,
            }
        }
    },


    //axis label config, if axis is having values
    axisLabelConfig: {
        formatter: function (value: any) {
            if (value >= 1000 && value < 1e6) {
                const result = value / 1000;
                return result % 1 === 0 ? `${result}k` : `${result.toFixed(1)}k`;
            } else if (value >= 1e6) {
                const result = value / 1e6;
                return result % 1 === 0 ? `${result}M` : `${result.toFixed(1)}M`;
            }
            return value;
        }
    },

     // Axis label config for terrain chart
     axisLabelConfigForTypeTimeInTerrain: {
        formatter: function (value: any, granularity: string) {
            const date = dayjs(value);
            if (granularity === timeIntervalsEnumLabel['DAILY']) {
                return date.format('MMM DD hhA'); // Example: "Nov 20 5PM" // groupBy Weekly,
            } else if (granularity === timeIntervalsEnumLabel['HOURLY']){
                return date.format('hh:mm A'); // Example: "05:30PM"
            } else {
                return date.format('MMMM DD'); // Example: "November 20"
            }
        }
    },
    
    axisIntervalConfig: {
        determineGranularity: (data: any[]) => {
            const start = dayjs(data[0]);
            const end = dayjs(data[data.length - 1]);
            const diffDays = end.diff(start, 'day');
            if (diffDays < 1) {
                return timeIntervalsEnumLabel['HOURLY']; // Single day
            } else if (diffDays < 31) {
                return timeIntervalsEnumLabel['DAILY']; // Up to 2 days
            } else {
                return timeIntervalsEnumLabel['MONTHLY']; // More than a month
            }
        },

        interval: (index: any, granularity: any, data: any[], visibleData?: any[]) => {
            const totalPoints = visibleData ? visibleData.length : data.length;
            if (granularity === timeIntervalsEnumLabel['MONTHLY']) {
                // Show only a few labels for monthly data
                const interval = Math.ceil(totalPoints / 5); // Show approximately 5 labels
                return index % interval === 0;
            } else if (granularity === timeIntervalsEnumLabel['DAILY']) {
                // Show fewer labels for daily data
                const interval = Math.ceil(totalPoints / 7); // Show approximately 7 labels
                return index % interval === 0;
            } else if (granularity === timeIntervalsEnumLabel['HOURLY']) {
                // Show hourly labels, but limit the count for high-density data
                const interval = Math.ceil(totalPoints / 10); // Show approximately 10 labels
                return index % interval === 0;
            } else {
                return index % 1 === 0; // Default: show all labels
            }
        }
    },

    labelNameTextStyle: {
        fontFamily: 'Lato',
        fontSize: 13,
        lineHeight: 14,
        color: '#999999'
    }

}


let globalMousePosition = { x: 0, y: 0 };
// get mouse position on mouse move event
document.addEventListener('mousemove', (event) => {
    globalMousePosition.x = event.pageX; // Mouse X relative to the body
    globalMousePosition.y = event.pageY; // Mouse Y relative to the body
  });