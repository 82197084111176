import dayjs from "dayjs";
import { timeIntervalsEnumLabel } from "../enums/dateRange.enum";

export const GRAPH_CONFIGURATION = {
    // zoom config when date is on x-axis
    xDate_ZoomConfig: [
        {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter',
            minValueSpan: 5,
        },
    ],

    // zoom config when date is on y-axis
    yDate_ZoomConfig: [
        {
            type: 'slider',
            yAxisIndex: 0,
            filterMode: 'filter',
            minValueSpan: 5,

        }
    ],

    // X-axis config when date is on x-axis
    xDate_AxisConfig: {
        type: 'time',
        name: '',
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
            fontFamily: 'Lato',
            fontSize: 13,
            lineHeight: 14,
            color: '#999999'
        },
        boundaryGap: false,
        splitNumber: 5,
        axisLabel: {},
        data: [],
        axisLine: {
            lineStyle: {
                color: '#CCCCCC'
            }
        },
        axisTick: {
            show: false,
        },
    },

    // Y-axis config when date is on Y-axis
    YDate_AxisConfig: [{
        type: 'time',
        boundaryGap: false,
        splitNumber: 5,
        name: '',
        nameLocation: 'middle',
        nameGap: 70,
        nameTextStyle: {
            fontFamily: 'Lato',
            fontSize: 13,
            lineHeight: 14,
            color: '#999999'
        },
        data: [],
        axisLabel: {},
        axisLine: {
            lineStyle: {
                color: '#CCCCCC'
            }
        },
        axisTick: {
            show: false,
        },
    }],

    // Tooltip trigger configuration
    toolTipTriggerConfig: {
        trigger: 'axis',
        renderMode: 'html',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        appendToBody: true,
        // position tooltip dynamically based on height of content
        position: function (point: any, params: any, dom: any, rect: any, size: any) {
            const mouseY = globalMousePosition.y;
            let topPosition = 0;
            // if content height is greater than view height then position tooltip based on mouse position
            if (size.contentSize[1] > size.viewSize[1]) { 
                topPosition = -(mouseY - point[1]);
            }

            let positionObj: any = { top: topPosition };
            if (point[0] < size.viewSize[0] / 2) { 
                positionObj.left = point[0];
            } else {
                positionObj.right = size.viewSize[0] - point[0];
            }
            return positionObj;
        },

        textStyle: {
            color: 'rgba(255, 255, 255, 1)',
            fontFamily: 'LATO',
            fontSize: 12
        },
        padding: 10,
        extraCssText: 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); border-radius: 8px;',
    },

    // Legend configuration
    legendConfig: {
        type: 'scroll',
        orient: 'horizontal',
        pageButtonPosition: 'end',
        left: "16px",
        top: "2px",
        icon: 'roundRect',
        animation: true,
        data: []
    },

    // Axis line configuration
    axisLineConfig: {
        lineStyle: {
            color: '#CCCCCC'
        }
    },

    // grid configuration if time is in x-axis
    xAxisgridConfig: {
        left: '2%',
        right: '2%',
        top: '60',
        bottom: '70',
        containLabel: true
    },

    // grid configuration if time is in y-axis
    yAxisgridConfig: {
        left: '4%',
        right: '10%',
        top: '60',
        bottom: '30',
        containLabel: true
    },

    //axis label config, if axis is having values
    axisLabelConfig: {
        formatter: function (value: any) {
            if (value >= 1000 && value < 1e6) {
                const result = value / 1000;
                return result % 1 === 0 ? `${result}k` : `${result.toFixed(1)}k`;
            } else if (value >= 1e6) {
                const result = value / 1e6;
                return result % 1 === 0 ? `${result}M` : `${result.toFixed(1)}M`;
            }
            return value;
        }
    },

    //Axis label config, if axis is having type as time
    axisLabelConfigForTypeTime: {
        formatter: {
            myear: '{yyyy}',
            month: '{MMMM}',
            day: '{MMM} {dd}',
            hour: '{hh}:{mm} {A}',
            minute: '{hh}:{mm} {A}',
        },
    },

    // Axis label config for box plot, if axis is having type as category
    axisLabelConfigForTypeTimeInBoxplot: {
        formatter: function (value: any, granularity: string) {
            const date = dayjs(value);
            if (granularity === timeIntervalsEnumLabel['DAILY']) {
                return date.format('MMM DD hhA'); // Example: "Nov 20 5PM" // groupBy Weekly,
            } else {
                return date.format('MMMM DD'); // Example: "November 20"
            }
        }
    },
    
    axisIntervalConfig: {
        determineGranularity: (data: any[]) => {
            const start = dayjs(data[0]);
            const end = dayjs(data[data.length - 1]);
            const diffDays = end.diff(start, 'day');
            if (diffDays < 1) {
                return timeIntervalsEnumLabel['HOURLY']; // Single day
            } else if (diffDays < 31) {
                return timeIntervalsEnumLabel['DAILY']; // Up to 2 days
            } else {
                return timeIntervalsEnumLabel['MONTHLY']; // More than a month
            }
        },

        interval: (index: any, granularity: any, data: any[], visibleData?: any[]) => {
            const totalPoints = visibleData ? visibleData.length : data.length;
            if (granularity === timeIntervalsEnumLabel['MONTHLY']) {
                // Show only a few labels for monthly data
                const interval = Math.ceil(totalPoints / 5); // Show approximately 5 labels
                return index % interval === 0;
            } else if (granularity === timeIntervalsEnumLabel['DAILY']) {
                // Show fewer labels for daily data
                const interval = Math.ceil(totalPoints / 7); // Show approximately 7 labels
                return index % interval === 0;
            } else if (granularity === timeIntervalsEnumLabel['HOURLY']) {
                // Show hourly labels, but limit the count for high-density data
                const interval = Math.ceil(totalPoints / 10); // Show approximately 10 labels
                return index % interval === 0;
            } else {
                return index % 1 === 0; // Default: show all labels
            }
        }
    },

    labelNameTextStyle: {
        fontFamily: 'Lato',
        fontSize: 13,
        lineHeight: 14,
        color: '#999999'
    },

    toolTipTriggerConfigForPieChart: {
       
        trigger: 'item',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 1)',

        // position tooltip dynamically based on height of content
        position: function (point:any,params:any, dom:any, rect:any, size:any) {
            const [mouseX, mouseY] = point;
            const { contentSize, viewSize } = size;
        
            let x = mouseX;
            let y = mouseY;
        
            // Adjust x position to prevent overflow
            if (mouseX + contentSize[0] > viewSize[0]) {
              x = mouseX - contentSize[0];
            }
        
            // Adjust y position to prevent overflow
            if (mouseY + contentSize[1] > viewSize[1]) {
              y = mouseY - contentSize[1];
            }
        
            return [x, y];
          },

        textStyle: {
            color: '#fff',
            fontFamily: 'LATO',
            fontSize: 11
        },
        padding: 10,
        extraCssText: 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); border-radius: 8px;',
    },

    sunburstLabelConfig: {
        left: '15px',
        top: 'bottom',
        text: '',
        textStyle: {
            fontSize: 14,
            color: '#999999',
            fontWeight: 400,
        },
    },
    sunburstSeriesConfig: {
        type: 'sunburst',
        sort: undefined,

        radius: ['20%', '95%'],
        label: {
            rotate: 'tangential',
            color: '#fff',
            fontSize: 12,
            width: 35,
            overflow: 'truncate',
            ellipsis: '..',
        },

        itemStyle: {
            borderWidth: 1,
            borderColor: '#fff',
        },
    },
    sunburstTooltipConfig: {
        trigger: 'item',
        renderMode: 'html',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
    },

    // Datazoom label configuration for boxplot 
    // Note: As the xAxis type is set to "category" for the boxplot, the chart will not format the dates on the DataZoom slider labels.
    datazoomLabelConfigForBoxplot: {
        "labelFormatter": function (index: any, value: any) {
            const d = new Date(value);
            return `${d.getFullYear()}-${(d.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")} ${d.getHours()
                .toString()
                .padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d.getSeconds()
                .toString()
                .padStart(2, "0")}`;
        }
    },

    gaugeSeriesConfig: {
        type: 'gauge',
        startAngle: 0,
        endAngle: 100,
        center: ['50%', '75%'],
        radius: '110%',
        min: 0,
        max: 100,
        splitNumber: 10,
        axisLine: {
            lineStyle: {
                width: 40,
                color: []
            }
        },
        pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '18%',
            width: 14,
            offsetCenter: [0, '-60%'],
            itemStyle: {
                color: 'auto'
            }
        },
        axisTick: {
            length: 8,
            lineStyle: {
                color: 'auto',
                width: 1
            }
        },
        splitLine: {
            length: 8,
            lineStyle: {
                color: 'auto',
                width: 1
            }
        },
        axisLabel: {
            show: true,
            color: '#464646',
            fontSize: 14,
            distance: -35,
            rotate: 'tangential',
        },
        title: {
            offsetCenter: [0, '0%'],
            fontSize: 16
        },
        detail: {
            fontSize: 32,
            offsetCenter: [0, '-15%'],
            valueAnimation: true,
            color: 'inherit'
        },
        data: [],
        animation: true
    }

}


let globalMousePosition = { x: 0, y: 0 };
// get mouse position on mouse move event
document.addEventListener('mousemove', (event) => {
    globalMousePosition.x = event.pageX; // Mouse X relative to the body
    globalMousePosition.y = event.pageY; // Mouse Y relative to the body
  });