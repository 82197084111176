import { Component, Input, OnInit } from '@angular/core';
import { WidgetLayoutService } from 'src/app/shared/_services/widget-layout.service';
import { GRAPH_CONFIGURATION } from 'src/app/shared/constants/graph-configuration';

@Component({
  selector: 'app-boxchart',
  templateUrl: './boxchart.component.html',
  styleUrls: ['./boxchart.component.scss']
})

export class BoxchartComponent implements OnInit {
  @Input() chartOptions: any;
  @Input() chartAxisPositions: any;
  
  constructor(public widgetLayoutService: WidgetLayoutService) { }


  ngOnInit(): void {
  }

  onChartInit(chart: any) {
    this.widgetLayoutService.updateAxisLabelPosition(chart, this.chartOptions, this.chartAxisPositions);
    this.subscribeToDataZoom(chart);
  }

  // Dynamically update interval and labels of chart
  subscribeToDataZoom(chart: any) {
    chart.on('datazoom', (params: any) => {
      const start = Math.floor((params.batch?.[0]?.start ?? 0) * (chart.getOption().xAxis[0].data.length / 100));
      const end = Math.ceil((params.batch?.[0]?.end ?? 100) * (chart.getOption().xAxis[0].data.length / 100));
      const visibleData = chart.getOption().xAxis[0].data.slice(start, end);
      // Dynamically update interval and labels
      chart.setOption({
        xAxis: {
            axisLabel: {
                interval: function (index: any) {
                    const granularity = GRAPH_CONFIGURATION.axisIntervalConfig.determineGranularity(visibleData);
                    return GRAPH_CONFIGURATION.axisIntervalConfig.interval(index, granularity, chart.getOption().xAxis[0].data, visibleData);
                },
                formatter: function (value: any, index: any) {
                    const granularity = GRAPH_CONFIGURATION.axisIntervalConfig.determineGranularity(visibleData);
                    return GRAPH_CONFIGURATION.axisLabelConfigForTypeTimeInBoxplot.formatter(value, granularity);
                },
            },
        },
      });
    });
  }
}
