import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-sunburstchart',
    templateUrl: './sunburstchart.component.html',
    styleUrls: ['./sunburstchart.component.scss']
})
export class SunburstchartComponent implements OnInit, OnChanges {
    @Input() chartOptions: any;
    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.chartOptions = changes['chartOptions'].currentValue;
    }

}
