import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WidgetService } from 'src/app/shared/_services/widget.service';
import { Chart } from 'src/app/shared/models/chart';
import { GRAPH_CONFIGURATION } from 'src/app/shared/constants/graph-configuration';
import * as _ from 'lodash-es';
import { chartname, chartType, chartViewType, viewByEnum, viewByEnumConvert} from 'src/app/shared/enums/charts.enum';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DashboardService } from 'src/app/shared/_services/dashboard.service';
import {  timeIntervalsEnum } from 'src/app/shared/enums/dateRange.enum';
import { CRUDOperationList } from 'src/app/shared/constants/constants';
import { ObjectUtil } from '../../shared/utils/object-util';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { axisOrientation } from 'src/app/shared/constants/widget';
import { GRAPH_CONFIGURATION_3D } from 'src/app/shared/constants/graph-configuration-3d';
import { UserPreference } from 'src/app/shared/models/common.model';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'app-widget-layout',
  templateUrl: './widget-layout.component.html',
  styleUrls: ['./widget-layout.component.scss']
})
export class WidgetLayoutComponent implements OnChanges, OnInit {
  @Input() widgetObj: any;
  private chartDataTransformWorker: Worker;
  widgetData: any;
  transformedChartData: any;
  isLoaded: boolean = false;
  showWarningMessage: boolean = false;
  warningMessage:string = "No data exists for this time period";
  crudAction:string;
  useDashBoardConfig:boolean = false;
  lineChartGroup = ['LINE', 'DOTTED_LINE', 'AREA', 'BAR'];
  chartAxisPositions:any = [];
  userPreference: UserPreference;
  isParamsDataLoaded: boolean = false;

  constructor(
    public widgetService: WidgetService,
    private sanitizer: DomSanitizer,
    public dashboardService: DashboardService
  ) {
    if (typeof Worker !== 'undefined') {
      this.chartDataTransformWorker = new Worker(new URL('src/app/shared/_workers/chart-data-transform.worker.ts', import.meta.url), {
        type: 'module',
      });
      this.chartDataTransformWorker.onmessage = ({ data }) => {
        this.generateChartData(data);
      };
    }
  }

  ngOnInit(): void {
    const userPreferenceData = localStorage.getItem('user_preference');
    this.userPreference = userPreferenceData ? JSON.parse(userPreferenceData) : null;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['widgetObj'] && changes['widgetObj'].currentValue) {
      this.crudAction = this.dashboardService.crudActionLabel;
      this.widgetObj = changes['widgetObj'].currentValue;
      if (!this.widgetObj?.isEmbedded) {
        this.isLoaded = false;
        this.getConfigAxisPositions();
        this.getChartData();
      }
    }
  }

  getConfigAxisPositions() {
    this.chartAxisPositions = [...new Set(this.widgetObj?.pointDefinitions?.map((point: any) => point?.axisPosition))];
  }

  getChartData() {

    const labels: { [key: string]: string } = timeIntervalsEnum;
    const viewByEnumMapping: { [key: string]: string } = viewByEnum;
    const viewByEnumConvertMapping: { [key: string]: string } = viewByEnumConvert;
    if (this.crudAction === CRUDOperationList.new || this.crudAction === CRUDOperationList.preview || this.crudAction === CRUDOperationList.edit || this.widgetObj?.builderWidgetPreview || this.crudAction === CRUDOperationList.duplicate) {
      let previewRequest:any;
      if ((this.crudAction === CRUDOperationList.new || this.crudAction === CRUDOperationList.preview || this.crudAction === CRUDOperationList.duplicate) && !this.widgetObj?.builderWidgetPreview) {
        this.useDashBoardConfig = true;
        previewRequest = {
          pointDefinitions: this.widgetObj?.pointDefinitions,
          "config": {
            "name": this.widgetObj?.name,
            "scopeSameAsDashboard": this.widgetObj?.scopeSameAsDashboard,
            "siteRefs": this.dashboardService.dashboardConfig.siteRefs,
            "excludedCcuRefs": this.dashboardService.dashboardConfig.excludedCcuRefs,
            "excludedFloorRefs": this.dashboardService.dashboardConfig.excludedFloorRefs,
            "excludedZoneRefs": this.dashboardService.dashboardConfig.excludedZoneRefs,
            "excludedEquipRefs": this.dashboardService.dashboardConfig.excludedEquipRefs,
            "excludedDeviceRefs": this.dashboardService.dashboardConfig.excludedDeviceRefs,
            "viewBy": this.widgetObj?.viewBy ? viewByEnumMapping[this.widgetObj?.viewBy] : viewByEnumMapping['Site'],
            "groupBySameAsDashboard": this.widgetObj?.groupBySameAsDashboard,
            "groupBy": labels[this.dashboardService.dashboardGroupBy],
            "dateRangeSameAsDashboard": this.widgetObj?.dateRangeSameAsDashboard,
            "startDate": this.dashboardService.dashboardDate.startDate,
            "endDate": this.dashboardService.dashboardDate.endDate,
          }
        }

        if (previewRequest?.config?.scopeSameAsDashboard) {
          previewRequest.config.siteRefs = this.dashboardService.dashboardConfig.siteRefs;
          previewRequest.config.excludedCcuRefs = this.dashboardService.dashboardConfig.excludedCcuRefs;
          previewRequest.config.excludedFloorRefs = this.dashboardService.dashboardConfig.excludedFloorRefs;
          previewRequest.config.excludedZoneRefs = this.dashboardService.dashboardConfig.excludedZoneRefs;
          previewRequest.config.excludedEquipRefs = this.dashboardService.dashboardConfig.excludedEquipRefs;
          previewRequest.config.excludedDeviceRefs = this.dashboardService.dashboardConfig.excludedDeviceRefs;
        } else {
          this.useDashBoardConfig = false;
          previewRequest.config.siteRefs = this.widgetObj?.scopeSelectionData?.siteRefs;
          previewRequest.config.excludedCcuRefs = this.widgetObj?.scopeSelectionData?.excludedCcuRefs;
          previewRequest.config.excludedFloorRefs = this.widgetObj?.scopeSelectionData?.excludedFloorRefs;
          previewRequest.config.excludedZoneRefs = this.widgetObj?.scopeSelectionData?.excludedZoneRefs;
          previewRequest.config.excludedEquipRefs = this.widgetObj?.scopeSelectionData?.excludedEquipRefs;
          previewRequest.config.excludedDeviceRefs = this.widgetObj?.scopeSelectionData?.excludedDeviceRefs;
        }

        if (previewRequest.config.groupBySameAsDashboard) {
          previewRequest.config.groupBy = labels[this.dashboardService.dashboardGroupBy];
        } else {
          this.useDashBoardConfig = false;
          previewRequest.config.groupBy = labels[this.widgetObj.groupBy];
        }

        if (previewRequest.config.dateRangeSameAsDashboard) {
          previewRequest.config.startDate = this.dashboardService.dashboardDate.startDate;
          previewRequest.config.endDate = this.dashboardService.dashboardDate.endDate;
          this.widgetObj.startDateTime = dayjs(this.dashboardService.dashboardDate.startDate);
          this.widgetObj.endDateTime = dayjs(this.dashboardService.dashboardDate.endDate);
        } else {
          this.useDashBoardConfig = false;
          previewRequest.config.startDate = dayjs(this.widgetObj.startDateTime).format('YYYY-MM-DD');
          previewRequest.config.endDate = dayjs(this.widgetObj.endDateTime).format('YYYY-MM-DD');
        }
      }

      if (this.crudAction === CRUDOperationList.edit && !this.widgetObj?.builderWidgetPreview) {
        this.useDashBoardConfig = true;
        previewRequest = {
          pointDefinitions: this.widgetObj?.pointDefinitions,
          "config": {
            "name": this.widgetObj?.name,
            "scopeSameAsDashboard": this.widgetObj?.scopeSameAsDashboard,
            "viewBy": viewByEnumMapping[this.widgetObj?.viewBy],
            "groupBySameAsDashboard": this.widgetObj?.groupBySameAsDashboard,
            "dateRangeSameAsDashboard": this.widgetObj?.dateRangeSameAsDashboard,
            "startDate": this.dashboardService.dashboardDate.startDate,
            "endDate": this.dashboardService.dashboardDate.endDate,
          }
        }

        if (previewRequest?.config?.scopeSameAsDashboard) {
          previewRequest.config.siteRefs = this.dashboardService.dashboardConfig.siteRefs;
          previewRequest.config.excludedCcuRefs = this.dashboardService.dashboardConfig.excludedCcuRefs;
          previewRequest.config.excludedFloorRefs = this.dashboardService.dashboardConfig.excludedFloorRefs;
          previewRequest.config.excludedZoneRefs = this.dashboardService.dashboardConfig.excludedZoneRefs;
          previewRequest.config.excludedEquipRefs = this.dashboardService.dashboardConfig.excludedEquipRefs;
          previewRequest.config.excludedDeviceRefs = this.dashboardService.dashboardConfig.excludedDeviceRefs;
        } else {
          this.useDashBoardConfig = false;
          previewRequest.config.siteRefs = this.widgetObj?.scopeSelectionData?.siteRefs;
          previewRequest.config.excludedCcuRefs = this.widgetObj?.scopeSelectionData?.excludedCcuRefs;
          previewRequest.config.excludedFloorRefs = this.widgetObj?.scopeSelectionData?.excludedFloorRefs;
          previewRequest.config.excludedZoneRefs = this.widgetObj?.scopeSelectionData?.excludedZoneRefs;
          previewRequest.config.excludedEquipRefs = this.widgetObj?.scopeSelectionData?.excludedEquipRefs;
          previewRequest.config.excludedDeviceRefs = this.widgetObj?.scopeSelectionData?.excludedDeviceRefs;
        }


        if (previewRequest.config.groupBySameAsDashboard) {
          previewRequest.config.groupBy = labels[this.dashboardService.dashboardGroupBy];
        } else {
          this.useDashBoardConfig = false;
          previewRequest.config.groupBy = labels[this.widgetObj.groupBy];
        }

        if (previewRequest.config.dateRangeSameAsDashboard) {
          previewRequest.config.startDate = this.dashboardService.dashboardDate.startDate;
          previewRequest.config.endDate = this.dashboardService.dashboardDate.endDate;
          this.widgetObj.startDateTime = dayjs(this.dashboardService.dashboardDate.startDate);
          this.widgetObj.endDateTime = dayjs(this.dashboardService.dashboardDate.endDate);
        } else {
          this.useDashBoardConfig = false;
          previewRequest.config.startDate =  dayjs(this.widgetObj.startDateTime).format('YYYY-MM-DD');
          previewRequest.config.endDate =  dayjs(this.widgetObj.endDateTime).format('YYYY-MM-DD');
        }
      }

      if (this.widgetObj?.builderWidgetPreview) {
        this.useDashBoardConfig = true;
        previewRequest = {
          pointDefinitions: this.widgetObj?.pointDefinitions,
          "config": {
            "name": this.widgetObj?.name,
            "scopeSameAsDashboard": true,
            "siteRefs": this.dashboardService.dashboardConfig.siteRefs,
            "excludedCcuRefs": this.dashboardService.dashboardConfig.excludedCcuRefs,
            "excludedFloorRefs": this.dashboardService.dashboardConfig.excludedFloorRefs,
            "excludedZoneRefs": this.dashboardService.dashboardConfig.excludedZoneRefs,
            "excludedEquipRefs": this.dashboardService.dashboardConfig.excludedEquipRefs,
            "excludedDeviceRefs": this.dashboardService.dashboardConfig.excludedDeviceRefs,
            "viewBy": this.widgetObj?.chartConfig?.viewBy ? viewByEnumConvertMapping[this.widgetObj?.chartConfig?.viewBy] : viewByEnumMapping['Site'],
            "groupBySameAsDashboard": true,
            "groupBy": labels[this.dashboardService.dashboardGroupBy],
            "dateRangeSameAsDashboard": true,
            "startDate": this.dashboardService.dashboardDate.startDate,
            "endDate": this.dashboardService.dashboardDate.endDate,
          }
        }
      }

      // passing chart type in pointdefinitions in preview request
      if (this.widgetObj?.pointDefinitions) {
        this.widgetObj.pointDefinitions.forEach((point: any) => {
          point.chartType = this.widgetObj.chartConfig.chartType;
        });
        previewRequest.pointDefinitions = this.widgetObj.pointDefinitions;
      }

      const { formattedStartDate, formattedEndDate } = this.formatDates(previewRequest.config?.startDate, previewRequest.config?.endDate);
      previewRequest.config.startDate = formattedStartDate;
      previewRequest.config.endDate = formattedEndDate;
      previewRequest.chartType =  this.widgetObj?.chartConfig?.chartType;
      this.widgetService.getWidgetPreview(previewRequest).subscribe((widgetData: any) => {
        if (widgetData.every((widget: any) => widget.entityData.length === 0)) {
          this.showWarningMessage = true;
          this.isLoaded = true;
        } else {
          this.showWarningMessage = false;
          this.widgetObj.data = widgetData;
          this.widgetObj.selectedParam = this.widgetObj?.chartConfig?.selectedParam || this.widgetObj?.pointDefinitions[0].name;
          this.widgetObj['ViewByENUM'] = previewRequest.config?.viewBy;
          this.chartDataTransformWorker.postMessage({widgetData: this.widgetObj, userPreference: this.userPreference, selectedParam : this.widgetObj?.selectedParam});
        }
      }, (error) => {
        if (error.status == 422 && error.error?.error.includes('Data point limit exceeded')) {
          this.showWarningMessage = true;
          this.warningMessage = 'API call limit reached for this chart. Please try limiting the chart scope/date range/parameter selection to view data';
          this.isLoaded = true;
        } else {
          this.showWarningMessage = true;
          this.warningMessage = error.error?.error;
          this.isLoaded = true;
        }
      });

    } else {
      let getDataObj:any;
      if (this.widgetObj?.widgetConfig) {
        getDataObj = {
          dashboardId: this.widgetObj?.widgetConfig?.dashboardId,
          widgetId: this.widgetObj?.widgetId,
          startDate: this.widgetObj?.startDateTime,
          endDate: this.widgetObj?.endDateTime,
        }
      }
      const { formattedStartDate, formattedEndDate } = this.formatDates(getDataObj.startDate, getDataObj.endDate);
      getDataObj.startDate = formattedStartDate;
      getDataObj.endDate = formattedEndDate;

      this.widgetService.getWidgetData(getDataObj).subscribe((widgetData: any) => {
        if (widgetData.every((widget: any) => widget.entityData.length === 0)) {
          this.showWarningMessage = true;
          this.warningMessage = 'No data Available';
          this.isLoaded = true;
        } else {
          this.showWarningMessage = false;
          this.widgetObj.data = widgetData;
          this.widgetObj['ViewByENUM'] = viewByEnumMapping[this.widgetObj?.viewBy];
          this.chartDataTransformWorker.postMessage({widgetData: this.widgetObj, userPreference: this.userPreference});
        }
      }, (error) => {
        if (error.status == 422 && error.error?.error.includes('Data point limit exceeded')) {
          this.showWarningMessage = true;
          this.warningMessage = 'API call limit reached for this chart. Please try limiting the chart scope/date range/parameter selection to view data';
          this.isLoaded = true;
        } else {
          this.showWarningMessage = true;
          this.warningMessage = error.error?.error;
          this.isLoaded = true;
        }
      });
    }
  }

  /**
   * Generates legend data for a given chart.
   *
   * This method clones the default legend configuration and populates it with
   * the names of points from the chart data that have the `showVisualisation` flag set to true.
   *
   * @param {Chart} chartData - The chart data containing point definitions.
   * @returns {LegendConfig} The legend configuration populated with the relevant point names.
   */
  generatelegendData(chartData: Chart) {
    let legendConfig: { data: string[] } = _.cloneDeep(GRAPH_CONFIGURATION.legendConfig);

    if (chartData.pointDefinitions?.length) {
      chartData.pointDefinitions.forEach((point: any) => {
        if (point.showVisualisation) {
          legendConfig.data.push(point.name);
        }
      });
    }
    return legendConfig;
  }


  /**
   * Generates axis configuration data for a chart based on the provided chart data, axis type, and selected parameter.
   *
   * @param {any} chartData - The data for the chart, including configuration and data arrays.
   * @param {string} axisType - The type of axis to generate data for (e.g., 'x', 'y', 'z').
   * @param {any} selectedParam - The selected parameter containing the name to be used for axis configuration.
   * @returns {any} The generated axis configuration object, or undefined if the axis configuration is not found.
   */
  generateTerrainAxisData(chartData: any, axisType: string, selectedParam: any) {
    let paramName: keyof typeof chartData.chartConfig = selectedParam.name || '';
    if (chartData?.chartConfig[paramName]?.axis[axisType]) {
      let axisConfig: any = _.cloneDeep(GRAPH_CONFIGURATION_3D.axisConfig_3D);
      let axisConfigData: any = chartData.chartConfig[paramName].axis[axisType];
      axisConfig.name = axisConfigData['legend label'] || '';

      if(axisConfigData['column'] === 'time') {
        axisConfig.type = 'category';
        axisConfig.data = chartData.dateArr;
        const granularity = GRAPH_CONFIGURATION_3D.axisIntervalConfig.determineGranularity(chartData.dateArr);
        axisConfig.axisLabel = {
          interval: function (index: any) {
            return GRAPH_CONFIGURATION_3D.axisIntervalConfig.interval(index, granularity, chartData.dateArr);
          },
          formatter: function (value: any) {
              return GRAPH_CONFIGURATION_3D.axisLabelConfigForTypeTimeInTerrain.formatter(value, granularity);
          }
        };
      }
      
      if(axisConfigData['column'] === 'scope') {
        axisConfig.type = 'category';
        let ind = chartData.data.findIndex((element: any) => element.paramName === selectedParam.name);
        axisConfig.data = chartData.data[ind].scopeArr;
        delete axisConfig.axisLabel;
      }
	  
      if(axisConfigData['column'] === 'value') {
        axisConfig.type = 'value';
        axisConfig.min = axisConfigData['min'] == 0 ? 0 :  axisConfigData['min'] || 'AUTO';
        axisConfig.max = axisConfigData['max'] == 0 ? 0 : axisConfigData['max'] || 'AUTO';

        axisConfig.axisLabel.formatter =  function (value:any) {
          return value % 1 === 0 ? value : value.toFixed(2);
        };
      }
      return axisConfig;
    }
  }

  /**
   * Generates tooltip data for a terrain chart.
   *
   * @param {any} chartData - The data used to generate the tooltip. It should contain:
   *   - `scopeLabel`: A label for the scope.
   *   - `dateArr`: An array of date strings.
   * @returns {Object} An object containing a formatter function for the tooltip.
   *   The formatter function takes `params` as an argument and returns a formatted string
   *   with the scope, time, and value.
   */
  generateTooltipDataForTerrain(chartData: any, param:any) {
    const self = this;
    let ind = chartData?.data?.findIndex((element: any) => element.paramName === param.name);
    const valueIndex: number = chartData?.data[ind]?.valueIndex;
    const timeIndex: number = chartData?.data[ind]?.timeIndex;
    let timeSeriesData: any = chartData?.dateArr;
    let graphConfig: any = GRAPH_CONFIGURATION_3D.toolTipTriggerConfig;
    graphConfig.formatter = function (params: any) {
      let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //get browser timezone
      let tooltipString: any = `<div class="text-align-left"><strong>${dayjs(timeSeriesData[params.data[timeIndex]]).tz(browserTimezone).format('@ ddd DD MMM, YYYY | hh:mm A')}</strong><br/>`;
        tooltipString += `<div class="toolTipStyle">
        <span style="color: ${params.color}; font-size: 12px;">
        ${params.data[4] != undefined ? params.data[4] : ''}: ${self.trimDecimalTo2Digit(params.data[valueIndex])}  
        ${params.data[3] != undefined ? params.data[3] : ''}
        </span>
        </span>
        </div>`;
      return tooltipString;
    }
    return graphConfig;
  }

  /**
   * Generates the configuration for the X-axis of a chart based on the provided chart data.
   *
   * @param {Chart} chartData - The chart data object containing configuration details.
   * @returns {any[]} - The configuration for the X-axis. If the X-axis column is 'time', 
   *                    it returns the predefined date axis configuration. Otherwise, 
   *                    it returns an array of configurations for the X-axis based on 
   *                    the provided chart data.
   */
  generateXAxisData(chartData: Chart) {
    // if x is time
    if (chartData.chartConfig?.axis?.xaxis?.column === 'time') {
      GRAPH_CONFIGURATION.xDate_AxisConfig.data = chartData.dateArr;
      let xConfig = _.cloneDeep(GRAPH_CONFIGURATION.xDate_AxisConfig);
      if (chartData.chartConfig.axis.xaxis['legend label'] != undefined) {
        xConfig['name'] = chartData.chartConfig.axis.xaxis['legend label'];
      }
      
      // if x is time and chart type is BOXPLOT, set x axis type to category
      if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
        xConfig = this.updateAxisConfigForBoxplot(xConfig, chartData);
      } else {
        // Set axisLabel to default configuration for time type
        xConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfigForTypeTime; 
      }

      return xConfig;
    } else { // if x is value
      let xAxisConfig: any = [];
      let downAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      let upAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      // min and max for x axis down axis 
      if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMax != undefined) {
        downAxisConfig['position'] = 'bottom';

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != 'AUTO') {
          downAxisConfig['min'] = chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin;
        }

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMax != 'AUTO') {
          downAxisConfig['max'] = chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMax;
        }

        if (!(this.chartAxisPositions.includes(axisOrientation.top) && this.chartAxisPositions.includes(axisOrientation.bottom))) {
          if (chartData.chartConfig.axis.xaxis['legend label'] != undefined) {
            downAxisConfig['name'] = chartData.chartConfig.axis.xaxis['legend label'];
            downAxisConfig['nameLocation'] = 'middle';
            downAxisConfig['nameGap'] = 30;
            downAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
          }
        }
        downAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        xAxisConfig.push(downAxisConfig);
      }

      // min and max for x axis up axis
      if (chartData?.chartConfig?.axis?.xaxis?.xaxisTopMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != undefined) {
        upAxisConfig['position'] = 'top';

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisTopMin != 'AUTO') {
          upAxisConfig['min'] = chartData?.chartConfig?.axis?.xaxis?.xaxisTopMin;
        }

        if (chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != 'AUTO') {
          upAxisConfig['max'] = chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax;
        }

        if (chartData.chartConfig.axis.xaxis['legend label'] != undefined) {
          upAxisConfig['name'] = chartData.chartConfig.axis.xaxis['legend label'];
          upAxisConfig['nameLocation'] = 'middle';
          upAxisConfig['nameGap'] = 30;
          upAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
        }
        upAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        xAxisConfig.push(upAxisConfig);
      }

      return xAxisConfig;
    }
  }

  /**
   * Setting the labels, ticks, and boundary gap for x-axis or y-axis in Box plot chart config.
   *
   * @param {string} axisConfig - x-axis or y-axis config.
   * @param {string} chartData - chart data.
   */
  updateAxisConfigForBoxplot(axisConfig: any, chartData: Chart) {
    axisConfig['type'] = 'category'; // Set type to category in x axis for boxplot
    // Set axisLabel to boxplot configuration 
    // Note: As the axis type is set to 'category' for boxplot, chart will not format the dates labels of x axis
    // So, we need to format the labels manually for boxplot
    const granularity = GRAPH_CONFIGURATION.axisIntervalConfig.determineGranularity(chartData.dateArr);
    axisConfig.axisLabel = {
      interval: function (index: any) {
        return GRAPH_CONFIGURATION.axisIntervalConfig.interval(index, granularity, chartData.dateArr);
      },
      formatter: function (value: any) {
          return GRAPH_CONFIGURATION.axisLabelConfigForTypeTimeInBoxplot.formatter(value, granularity);
      }
    };
    axisConfig.axisTick = { show: true, alignWithLabel: true } as any;
    axisConfig['boundaryGap'] = true;
    return axisConfig;
  }

  /**
   * Generates the Y-axis configuration for a given chart based on its configuration.
   * 
   * @param {Chart} chartData - The chart data object containing configuration details.
   * @returns {Array<Object>} - An array of Y-axis configuration objects.
   * 
   * The function checks if the Y-axis column is set to 'value'. If so, it generates
   * the configuration for the left and right Y-axes based on the provided minimum
   * and maximum values. If the min or max values are set to 'AUTO', they are not included
   * in the configuration. If the Y-axis column is not 'value', it returns a default
   * date axis configuration.
   */
  generateYAxisData(chartData: Chart) {
    // if y is value 
    if (chartData.chartConfig?.axis?.yaxis?.column === 'value') {
      let yAxisConfig: any = [];
      let leftAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      let rightAxisConfig: any = {
        axisLine: GRAPH_CONFIGURATION.axisLineConfig
      }

      // min and max for y axis left axis
      if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMax != undefined) {
        leftAxisConfig['position'] = 'left';
        if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != 'AUTO') {
          leftAxisConfig['min'] = chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin;
        }

        if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMax != 'AUTO') {
          leftAxisConfig['max'] = chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMax;
        }

        if (chartData.chartConfig.axis.yaxis['legend label'] != undefined) {
          leftAxisConfig['name'] = chartData.chartConfig.axis.yaxis['legend label'];
          leftAxisConfig['nameLocation'] = 'middle';
          leftAxisConfig['nameGap'] = 40;
          leftAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
        }
        leftAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        yAxisConfig.push(leftAxisConfig);
      }

      // min and max for y axis right axis
      if (chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMax != undefined) {
        rightAxisConfig['position'] = 'right';

        if (chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != 'AUTO') {
          rightAxisConfig['min'] = chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin;
        }

        if (chartData?.chartConfig?.axis?.yaxis?.yaxisRightMax != 'AUTO') {
          rightAxisConfig['max'] = chartData?.chartConfig?.axis?.yaxis?.yaxisRightMax;
        }

        if (!(this.chartAxisPositions.includes(axisOrientation.left) && this.chartAxisPositions.includes(axisOrientation.right))) {
          if (chartData.chartConfig.axis.yaxis['legend label'] != undefined) {
            rightAxisConfig['name'] = chartData.chartConfig?.axis?.yaxis['legend label'];
            rightAxisConfig['nameLocation'] = 'middle';
            rightAxisConfig['nameGap'] = 40;
            rightAxisConfig['nameTextStyle'] = GRAPH_CONFIGURATION.labelNameTextStyle;
          }
        }

        rightAxisConfig['axisLabel'] = GRAPH_CONFIGURATION.axisLabelConfig;
        yAxisConfig.push(rightAxisConfig);
      }

      return yAxisConfig;
    } else { // if y is time
      GRAPH_CONFIGURATION.YDate_AxisConfig[0].data = chartData.dateArr;
      if (chartData.chartConfig?.axis?.yaxis['legend label'] != undefined) {
        GRAPH_CONFIGURATION.YDate_AxisConfig[0].name = chartData.chartConfig.axis.yaxis['legend label'];
      }
      // if y is time and chart type is BOXPLOT, set y axis type to category
      if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
        GRAPH_CONFIGURATION.YDate_AxisConfig[0] = this.updateAxisConfigForBoxplot(GRAPH_CONFIGURATION.YDate_AxisConfig[0], chartData);
      } else {
        // Set axisLabel to default configuration for time type
        GRAPH_CONFIGURATION.YDate_AxisConfig[0].axisLabel = GRAPH_CONFIGURATION.axisLabelConfigForTypeTime; 
      }
      return GRAPH_CONFIGURATION.YDate_AxisConfig;
    }
  }


  /**
   * Generates series data for a chart based on the provided chart data.
   * 
   * @param {Chart} chartData - The chart data containing configuration and data points.
   * @returns {Promise<any>} A promise that resolves to the generated series data.
   * 
   * The function processes the chart data and generates series data for visualization.
   * It iterates through the data points and applies various configurations such as
   * chart type, color, line style, area style, and axis indices based on the chart configuration.
   * 
   * The function supports different chart types including 'DOTTED_LINE' and 'AREA', and
   * adjusts the styles accordingly. It also sets the xAxisIndex and yAxisIndex based on
   * the axis configuration provided in the chart data.
   * 
   * If an error occurs during the processing, the promise is rejected with the error.
   */
  generateSeriesData(chartData: Chart, pointName?:string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let seriesData: any = [];

        if (chartData.data?.length) {
          chartData.data.forEach((pointData: any) => {
            let pointDefinitionObj: any;
            if(chartData?.chartConfig?.chartType === chartType.TERRAIN) {
              pointDefinitionObj = chartData.pointDefinitions.find((point: any) => point.name === pointData.paramName && point.name === pointName);
            } else {
              pointDefinitionObj = chartData.pointDefinitions.find((point: any) => point.name === pointData.paramName);
            }
            if (pointDefinitionObj?.showVisualisation && pointData.entityData?.length) {

              pointData.entityData.forEach((hisPointObj: any) => {

                let pointObj: any = {};
                pointObj.name = pointData.paramName;
                pointObj.unit = hisPointObj?.unit?.value;
                pointObj.defaultNameForExports = hisPointObj.defaultNameForExports;
                pointObj.type = chartname[chartData.chartConfig.chartType as keyof typeof chartname];
                pointObj.symbol = 'none';
                pointObj.itemStyle = {
                  color: pointDefinitionObj.color
                };

                // For Dotted Line Chart Type add style for line
                if (chartData.chartConfig.chartType === 'DOTTED_LINE') {
                  pointObj.lineStyle = {
                    type: 'dotted',
                    width: 2,
                  }
                }

                // For Area Chart Type add style for area and remove line
                if (chartData.chartConfig.chartType === 'AREA') {
                  pointObj.areaStyle = {};
                  pointObj.lineStyle = {
                    width: 0
                  }
                }

                pointObj['data'] = hisPointObj.chartTransformedArr;
                
                // For Boxplot Chart Type add style for border color
                if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
                  // Add unit to each data array
                  pointObj.data = pointObj.data.map((ValuesArray: any) => {
                    ValuesArray.push(pointObj.unit);
                    return ValuesArray;
                  });
                  pointObj.itemStyle['borderColor'] = pointDefinitionObj.color;
                  delete pointObj.itemStyle['color']; // Remove color for boxplot.
                }

                if(chartData.chartConfig.chartType === chartType.TERRAIN) {
                  pointObj.type = 'bar3D';
                  pointObj.shading = 'lambert';
                  pointObj.emphasis = {
                    label: {
                      show: false,
                    }
                  };
                  pointObj.label = {
                    show: false
                  }
                  delete pointObj.symbol;
                }

                // setting the xAxisIndex up or down for each point
                if (chartData?.chartConfig?.axis?.xaxis?.column == "value") {
                  if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != undefined) {
                    pointObj['xAxisIndex'] = pointDefinitionObj?.axisPosition == 'BOTTOM' ? 0 : 1;
                  }

                  // if only one x axis is defined
                  if (chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin != undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax == undefined ||
                    chartData?.chartConfig?.axis?.xaxis?.xaxisBottomMin == undefined && chartData?.chartConfig?.axis?.xaxis?.xaxisTopMax != undefined) {
                    pointObj['xAxisIndex'] = 0
                  }
                }

                // setting the yAxisIndex left or right for each point
                if (chartData?.chartConfig?.axis?.yaxis?.column == "value") {
                  if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != undefined) {
                    pointObj['yAxisIndex'] = pointDefinitionObj?.axisPosition == 'RIGHT' ? 1 : 0;
                  }

                  // if only one y axis is defined
                  if (chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin != undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin == undefined ||
                    chartData?.chartConfig?.axis?.yaxis?.yaxisLeftMin == undefined && chartData?.chartConfig?.axis?.yaxis?.yaxisRightMin != undefined) {
                    pointObj['yAxisIndex'] = 0
                  }
                }
                
                seriesData.push(pointObj);
                
              });
            }
          });
        }
        resolve(seriesData);
      } catch (error) {
        reject(error);
      }
    });

  }

  /**
   * Generates a tooltip configuration for a chart based on the provided chart data.
   * 
   * @param {any} chartData - The data for the chart, which includes the chart configuration.
   * @returns {any} The tooltip configuration object.
   */
  generateTooltip(chartData: any) {
    let graphConfig: any = { ...GRAPH_CONFIGURATION.toolTipTriggerConfig };
    let self = this;
    if (this.lineChartGroup.includes(chartData.chartConfig.chartType)) {
      graphConfig.formatter = function (params: any) {
        let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //get browser timezone
        // convert UTC time to browser time 
        let tooltipString: any = `<div class="text-align-left"><strong>${dayjs(params[0].axisValueLabel).tz(browserTimezone).format('@ ddd DD MMM, YYYY | hh:mm A')}</strong><br/>`;

        // sortBy value
        if (chartData.chartConfig.tooltip.sortBy == "sortview") { 
          params = [...params].sort((a, b) => {
            const aValue = a.data[2];
            const bValue = b.data[2];

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
          });
        } 
        params.forEach(function (item: any) {
          tooltipString += `<div class="toolTipStyle">
            <span style="color: ${item.color}; font-size: 12px;">
            ${item.value[2]}: ${self.trimDecimalTo2Digit(chartData.chartConfig.axis.xaxis.column == 'time' ? item.value[1] : item.value[0])}
            ${item.value[3] ? '': (chartData.chartConfig.axis.xaxis.column == 'time' ? ObjectUtil.isNotUndefinedOrNull(item.value[1]) : ObjectUtil.isNotUndefinedOrNull(item.value[0])) ? item.value[4]: ''}</span>
            ${item.value[3] ? `<i class="fa fa-solid fa-calculator calc-icon-color p-l-5"></i>`: ''}
          </div>`;
        });
        return tooltipString;
      }
    } else if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
      graphConfig.trigger = 'item';
      this.getTooltipDataForBoxPlot(graphConfig);
      console.log('graphConfig', graphConfig);
      
    }
    return graphConfig;
  }

  getTooltipDataForBoxPlot(graphConfig: any) { 
    const self = this;
    graphConfig.formatter = function (param: any) {
      let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //get browser timezone
      // convert UTC time to browser time
      let tooltipString: any = `<div class="text-align-left"><strong>${dayjs(param.name).tz(browserTimezone).format('@ ddd DD MMM, YYYY | hh:mm A')}</strong><br/>`;

        // item = [index, min, Q1, median, Q3, max, value, tooltipConfig, isDerived, unit]
        tooltipString += `<div class="toolTipStyle">
        <span style="color: ${param.color}; font-size: 12px;">
          ${param.value[6]}
          ${param.value[7] ? `<i class="fa fa-solid fa-calculator calc-icon-color p-l-5"></i>`: ''} <br/>
          Min: ${self.trimDecimalTo2Digit(param.value[1])} ${param.value[7] ? '': param.value[1] ? param.value[8]: ''}<br/>
          Q1: ${self.trimDecimalTo2Digit(param.value[2] )} ${param.value[7] ? '': param.value[2] ? param.value[8]: ''}<br/>
          Median: ${self.trimDecimalTo2Digit(param.value[3] )} ${param.value[7] ? '': param.value[3] ? param.value[8]: ''}<br/>
          Q3: ${self.trimDecimalTo2Digit(param.value[4] )} ${param.value[7] ? '': param.value[4] ? param.value[8]: ''}<br/>
          Max: ${self.trimDecimalTo2Digit(param.value[5] )} ${param.value[7] ? '': param.value[5] ? param.value[8]: ''}
        </span>
        </div>`;
      return tooltipString;
    }
  }

  /**
   * Trims a given number to 2 decimal places if it has more than 2 decimal places.
   *
   * @param value - The number to be trimmed.
   * @returns The number trimmed to 2 decimal places if it had more than 2, otherwise returns the original number.
   */
  trimDecimalTo2Digit(value: number) {
    if (!ObjectUtil.isNotUndefinedOrNull(value)) {
      return '-';
    }
    if (value && value.toString().includes('.') && value.toString().split('.')[1].length > 2) {
      return parseFloat(value.toFixed(2)); // Trim to 2 decimals
    }
    return value;
  }


  /**
   * Generates and transforms chart data based on the provided chart configuration.
   * 
   * @param chartData - The chart data and configuration used to generate the chart.
   * @returns void
   * 
   * @remarks
   * This method sets the `transformedChartData` property with the appropriate configurations
   * for data zoom, tooltip, legend, x-axis, y-axis, and series based on the provided chart data.
   * It also sets the `isLoaded` flag to true once the transformation is complete.
   */
  async generateChartData(chartData: any) {
    let chartConfigObj: any = {};
    let lineChartGroup = ['LINE', 'DOTTED_LINE', 'AREA', 'BAR'];
    if (chartData.chartConfig.chartType == chartType.PIE || chartData.chartConfig.chartType == chartType.DONUT) {
      this.generateChartDataForPieDonutChart(chartData);
    } else if(chartData.chartConfig.chartType == chartType.GAUGE) {
      this.generateChartDataForGaugeChart(chartData);
    }
    else if (chartData.chartConfig.chartType == chartType.SUNBURST) {
      this.generateChartDataForSunburst(chartData);
    } else if (chartData.chartConfig.chartType == chartType.TERRAIN) {
      this.generateChartDataForTerrain(chartData);
    }
     else {
      chartConfigObj = {
        dataZoom: chartData?.chartConfig?.axis?.xaxis?.column == 'time' ? GRAPH_CONFIGURATION.xDate_ZoomConfig : GRAPH_CONFIGURATION.yDate_ZoomConfig,
        tooltip: this.generateTooltip(chartData),
        grid: chartData?.chartConfig?.axis?.xaxis?.column == 'time' ? GRAPH_CONFIGURATION.xAxisgridConfig : GRAPH_CONFIGURATION.yAxisgridConfig,
        legend: this.generatelegendData(chartData),
        xAxis: this.generateXAxisData(chartData),
        yAxis: this.generateYAxisData(chartData),
      }

      if(chartData.chartConfig.chartType === chartType.BOX_PLOT) {
        chartConfigObj.dataZoom[0]['showDataShadow'] = true; // Set showDataShadow to true in data zoom for boxplot
        // Set label formatter for data zoom for boxplot 
        // Note: As the xAxis type is set to "category" for the boxplot, the chart will not format the dates on the DataZoom slider labels.
        const dataZoom = {...chartConfigObj.dataZoom[0], ...GRAPH_CONFIGURATION.datazoomLabelConfigForBoxplot};
        chartConfigObj.dataZoom[0] = dataZoom;
      }

      //If chartType is COMBINE, generate series data for all sites
      if (chartData.chartConfig.viewType === chartViewType.COMBINE) {
        this.transformedChartData = [];
        try {
          chartConfigObj.series = await this.generateSeriesData(chartData);
          this.transformedChartData.push(chartConfigObj);
          this.isLoaded = true;
        } catch (error) {
          console.error('Error generating series data for COMBINE view type', error);
        }
      } else { //If chartType is SEPERATE, generate series data for each site
        let listOfSites = chartData.scopeSameAsDashboard || this.useDashBoardConfig ? this.dashboardService.dashboardConfig.siteRefs : (this.crudAction == CRUDOperationList.new) ? chartData.scopeSelectionData.siteRefs: chartData.widgetConfig.siteRefs;
        if (listOfSites.length) {
          let chartDataGroupedBySite = listOfSites.map((site: string) => ({
            siteRef: site,
            data: chartData.data.map((chartDataObj: any) => ({
              paramName: chartDataObj.paramName,
              entityData: chartDataObj?.entityData ? chartDataObj.entityData.filter((entity: any) => entity.siteRef === site) : []
            })).filter((filterItem: any) => filterItem.entityData.length > 0)
          }));

          this.transformedChartData = [];

          const generateSeriesDataPromises = chartDataGroupedBySite.map(async (siteData: any) => {
            let siteLevelWidgetData: Chart = _.cloneDeep(chartData);
            siteLevelWidgetData.data = siteData.data;
            let siteWidgetConfig = _.cloneDeep(chartConfigObj);
            const seriesData = await this.generateSeriesData(siteLevelWidgetData);
            siteWidgetConfig.series = seriesData;
            siteWidgetConfig.siteName = siteData.data[0]?.entityData[0]?.siteName !== undefined ? siteData.data[0]?.entityData[0]?.siteName : '';
            if (seriesData?.length) {
              this.transformedChartData.push(siteWidgetConfig);
            }
          });

          try {
            await Promise.all(generateSeriesDataPromises);
            this.isLoaded = true;
          } catch (error) {
            this.isLoaded = true;
            // Need to show Error message in UI
            console.error('Error generating series data', error);
          }
        }
      }
    }
  }

  async generateChartDataForTerrain(chartData: any) {
    let self = this;
    let chartConfigObj: any = {};
    let allParametersConfig: any = [];
    this.transformedChartData = [];
    let siteArr: any = [];
  
    for (const point of chartData.pointDefinitions) {
      if (point.showVisualisation) {
        chartConfigObj = {
          grid3D: GRAPH_CONFIGURATION_3D.grid3DConfig,
          tooltip: self.generateTooltipDataForTerrain(chartData, point),
          xAxis3D: self.generateTerrainAxisData(chartData, 'xaxis', point),
          yAxis3D: self.generateTerrainAxisData(chartData, 'yaxis', point),
          zAxis3D: self.generateTerrainAxisData(chartData, 'zaxis', point),
        };
      }
  
      if (chartData.chartConfig.viewType === chartViewType.COMBINE) {
        try {
          chartConfigObj.series = await self.generateSeriesData(chartData, point.name);
          chartConfigObj['visualMap'] = this.setVisualMap(chartData, point.name);
  
          allParametersConfig.push({
            parameterName: point.name,
            chartOptions: _.cloneDeep(chartConfigObj),
            showVisualisation: point.showVisualisation,
          });
        } catch (error) {
          self.isLoaded = true;
          console.error('Error generating series data for COMBINE view type', error);
        }
      } else {
        let listOfSites = chartData.scopeSameAsDashboard || self.useDashBoardConfig ? self.dashboardService.dashboardConfig.siteRefs : (this.crudAction == CRUDOperationList.new) ? chartData.scopeSelectionData.siteRefs: chartData.widgetConfig.siteRefs;
        if (listOfSites.length) {
          let chartDataGroupedBySite = listOfSites.map((site: string) => ({
            siteRef: site,
            data: chartData.data.map((chartDataObj: any) => ({
              paramName: chartDataObj.paramName,
              entityData: chartDataObj?.entityData ? chartDataObj.entityData.filter((entity: any) => entity.siteRef === site) : [],
            })).filter((filterItem: any) => filterItem.entityData.length > 0),
          }));
          for (const siteData of chartDataGroupedBySite) {
            let siteLevelWidgetData: Chart = _.cloneDeep(chartData);
            siteLevelWidgetData.data = siteData.data;
            let siteWidgetConfig = _.cloneDeep(chartConfigObj);
            try {
              const seriesData = await self.generateSeriesData(siteLevelWidgetData, point.name);
              siteWidgetConfig.series = seriesData;
              siteWidgetConfig['visualMap'] = this.setVisualMap(siteLevelWidgetData, point.name);
              siteWidgetConfig.siteName = siteData.data[0]?.entityData[0]?.siteName ?? '';
              let ind = chartData?.data?.findIndex((element: any) => element.paramName === point.name);
              siteWidgetConfig[chartData?.data[ind]?.scopeAxis].data = chartData.data[ind].scopeArr[siteData.data[0]?.entityData[0]?.siteName];
              if(!siteArr.includes(siteData.data[0]?.entityData[0]?.siteName)) {
                siteArr.push(siteData.data[0]?.entityData[0]?.siteName);
              }
              let siteInd = siteArr.indexOf(siteData.data[0]?.entityData[0]?.siteName);
              self.transformedChartData[siteInd] = self.transformedChartData[siteInd] || [];
              self.transformedChartData[siteInd].push({
                  parameterName: point.name,
                  siteName:siteWidgetConfig.siteName,
                  chartOptions: _.cloneDeep(siteWidgetConfig),
                  showVisualisation: point.showVisualisation,
                });
            } catch (error) {
              console.error('Error generating series data for SEPARATE view type', error);
            }
          }
        }
      }
    }
    if(chartData.chartConfig.viewType === chartViewType.COMBINE){
      self.transformedChartData.push(allParametersConfig);
    }

    this.transformedChartData = this.updateDataforLegends(this.transformedChartData);

    this.isLoaded = true;
  }

  /**
   * Configures the visual map for a chart based on the provided chart data and point name.
   *
   * @param {any} chartData - The data used to configure the chart, including configuration and data points.
   * @param {string} pointName - The name of the point in the chart configuration to be used for setting the visual map.
   * @returns {any} The visual map configuration object.
   */
  setVisualMap(chartData: any, pointName: string) {
    let visualMapConfig: any = {};
    let pointUnit: string | undefined;
    const paramData = chartData.data.find((element: any) => element.paramName === pointName);
    if (paramData) {
      const entityWithUnit = paramData.entityData.find((entity: any) => entity.unit?.value);
      pointUnit = entityWithUnit?.unit?.value;
    }
    visualMapConfig['left'] = 'left';
    visualMapConfig['top'] = 'bottom';
    const tsData = this.getTsDataByParamName(chartData.data, pointName);

    visualMapConfig['max'] = this.getMaxMinValue(chartData.chartConfig[pointName]?.axis, tsData).maxValue;
    visualMapConfig['min'] = this.getMaxMinValue(chartData.chartConfig[pointName]?.axis, tsData).minValue;
    visualMapConfig['inRange'] = {};
    visualMapConfig['calculable'] = true,
    visualMapConfig['dimension'] = this.findAxisWithValueColumn(chartData.chartConfig[pointName]), 
    visualMapConfig.formatter = function (value: any) {
      if (typeof value === 'number' && value % 1 !== 0) {
        value = value.toFixed(1);
      }
      return value + ' ' + (pointUnit != undefined ? pointUnit : '');
    }
    visualMapConfig['inRange']['color'] = [chartData.chartConfig[pointName]?.color?.stopColor0, chartData.chartConfig[pointName]?.color?.stopColor100];
    return visualMapConfig;
  }

 getTsDataByParamName(data: any[], paramName: string) {
    const paramData = data.find(item => item.paramName === paramName);
    return paramData ? paramData.entityData.flatMap((entity:any) => entity.tsData) : null;
  }

  findAxisWithValueColumn(config:any) {
    const axes = config.axis;
    const axisMapping:any = {
      xaxis: 0,
      yaxis: 1,
      zaxis: 2
    };
    for (const axis in axes) {
      if (axes[axis].column === "value") {
        return axisMapping[axis];
      }
    }
    return 1; 
  }

  /**
   * Calculates the maximum and minimum values from a given data array based on the axis configuration.
   *
   * @param axisConfig - The configuration object for the axis, which includes the column and min/max values.
   * @param dataArr - The array of data objects to evaluate.
   * @returns An object containing the calculated `maxValue` and `minValue`.
   */
  getMaxMinValue(axisConfig: any, dataArr: any) {
    let axisKey: string = '';
    for (const key in axisConfig) {
      if (axisConfig[key]?.column === 'value') {
        axisKey =  key;
      }
    }
    let maxValue = axisConfig[axisKey]?.max;
    if(maxValue === 'AUTO') {
      maxValue = dataArr.reduce((max:number, obj:any) => (obj.value > max ? obj.value : max), -Infinity);
    }
    let minValue = axisConfig[axisKey]?.min;
    if(minValue === 'AUTO') {
      minValue =  dataArr.reduce((min:number, obj:any) => (obj.value < min ? obj.value : min), Infinity);
    }
    maxValue = maxValue * 1;
    minValue = minValue * 1;
    return {maxValue, minValue};
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * Formats the given start and end dates to UTC and returns them in the 'YYYY-MM-DDTHH:mm:ssZ' format.
   *
   * @param startDate - The start date as a string.
   * @param endDate - The end date as a string.
   * @returns An object containing the formatted start and end dates.
   * 
   * @example
   * ```typescript
   * const dates = formatDates('2023-10-01', '2023-10-10');
   * console.log(dates);
   * // Output: { formattedStartDate: '2023-10-01T00:00:00Z', formattedEndDate: '2023-10-10T23:59:59Z' }
   * ```
   */
  formatDates(startDate: string, endDate: string) {
    const currentDateTime = dayjs().utc();
    const formattedStartDate = dayjs(startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

    let formattedEndDate;
    if (endDate && dayjs(endDate).isAfter(currentDateTime, 'day') || dayjs(endDate).isSame(currentDateTime, 'day')) {
      formattedEndDate = currentDateTime.format('YYYY-MM-DDTHH:mm:ss[Z]');
    } else {
      formattedEndDate = dayjs(endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    }
    return { formattedStartDate, formattedEndDate };
  }

  generateSeriesDataForPieChart(chartData: Chart): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let seriesData: any = [];
        if (chartData.data?.length) {
          const dataForChartsInput = chartData?.data
            .flatMap((item: any) =>
              item?.entityData?.map((entity: any) => ({
                ...entity,
                paramName: item?.paramName
              }))
            )
            .filter((item: any) => item?.allowDisplay)
            .map((entity: {
              itemStyle: any;
              count: number;
              name: string;
              defaultNameForExports: string;
              paramName: string;
              aggregationMethod: string;
              unit: any;
              value: number;
            }) => ({
              value: entity?.count ? entity?.count : entity?.value,
              name: entity?.name,
              itemStyle: entity?.itemStyle,
              defaultNameForExports: entity?.defaultNameForExports,
              paramName: entity?.paramName,
              unit: entity?.aggregationMethod != 'COUNT' ? entity?.unit?.value : ''
            }));
          let pointObj: any = {};
          pointObj.name = 'Label Placeholder';
          pointObj.type = 'pie';
          pointObj['data'] = dataForChartsInput;
          pointObj.emphasis = {
            itemStyle: {
              shadowBlur: 20,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
          pointObj.center = ['50%', '50%'],
          pointObj.radius = chartData.chartConfig.chartType == chartType.DONUT ?  ["30%", "50%"] : '50%';
            seriesData.push(pointObj);
        }
        resolve(seriesData);
      } catch (error) {
        reject(error);
      }
    });
  }

  //Method which sets data for e-charts in case of both pie and dougnut chart and view type series or combine:
  async generateChartDataForPieDonutChart(chartData: any) {
    let graphConfig: any = {};

    graphConfig.tooltip = this.generateTooltipForPieDoughnut(chartData);
    graphConfig.legend = {
      type: 'scroll',
      orient: 'horizontal',
      pageButtonPosition: 'end',
      left: "3%",
      top: "2px",
      icon: 'roundRect',
      animation: true,
    } 

    if (chartData.chartConfig.chartType == chartType.DONUT) {
      graphConfig.startAngle = 0;
      graphConfig.endAngle = 360;
    } 
    //If chartType is COMBINE, generate series data for all sites
    if (chartData.chartConfig.viewType === chartViewType.COMBINE) {
      this.transformedChartData = [];
      try {
        graphConfig.series = await this.generateSeriesDataForPieChart(chartData);
        graphConfig.title =  [
          {
            text: chartData.chartConfig['chartLabel'],
            left: '15px',
            top: 'bottom',
            textStyle: {
              fontSize: 14,
              color: '#999999',
              fontWeight: 400
            }
          }
        ]
        this.transformedChartData.push(graphConfig);
        this.isLoaded = true;
      } catch (error) {
        console.error('Error generating series data for COMBINE view type', error);
      }
    } 
    
    //If chartType is SEPERATE, generate series data for each site
    else {
      let listOfSites = chartData.scopeSameAsDashboard || this.useDashBoardConfig ? this.dashboardService.dashboardConfig.siteRefs : (this.crudAction == CRUDOperationList.new) ? chartData.scopeSelectionData.siteRefs: chartData.widgetConfig.siteRefs;
      if (listOfSites?.length) {
        let chartDataGroupedBySite = listOfSites?.map((site: string) => ({
          siteRef: site,
          data: chartData?.data?.map((chartDataObj: any) => ({
            paramName: chartDataObj?.paramName,
            entityData: chartDataObj?.entityData ? chartDataObj.entityData.filter((entity: any) => entity.siteRef === site): []
          })).filter((filterItem: any) => filterItem.entityData.length > 0)
        }));

        this.transformedChartData = [];

        const generateSeriesDataPromises = chartDataGroupedBySite?.map(async (siteData: any, index:number) => {
          let siteLevelWidgetData: Chart = _.cloneDeep(chartData);
          siteLevelWidgetData.data = siteData?.data;
          let siteWidgetConfig = _.cloneDeep(graphConfig);
          const seriesData = await this.generateSeriesDataForPieChart(siteLevelWidgetData);
          siteWidgetConfig.series = seriesData;
          siteWidgetConfig.siteName = siteData?.data[0]?.entityData[0]?.siteName !== undefined ? siteData.data[0]?.entityData[0]?.siteName : '';
          if(index == chartDataGroupedBySite.length -1) {
            siteWidgetConfig.title =  [
              {
                text: chartData.chartConfig['chartLabel'],
                left: '15px',
                top: 'bottom',
                textStyle: {
                  fontSize: 14,
                  color: '#999999',
                  fontWeight: 400
                }
              }
            ]
          }
          if(siteWidgetConfig.series.length)
          this.transformedChartData.push(siteWidgetConfig);
        });

        try {
          await Promise.all(generateSeriesDataPromises);
          this.isLoaded = true;
        } catch (error) {
          this.isLoaded = true;
          // Need to show Error message in UI
          console.error('Error generating series data', error);
        }
      }
    }
  }


  generateTooltipForSunburst(chartData: any, parameterName: string) {
    const self = this;
    let sunburstTooltipConfig: any = GRAPH_CONFIGURATION.sunburstTooltipConfig;
    sunburstTooltipConfig.formatter = function (params: any) {
      if(params.dataIndex !== 0) {
      return `<div style="font-size:12px;font-family:'Lato';font-weight: 700;"> 
      <span style="color:${params.color};">${params.name} - ${parameterName} : ${self.trimDecimalTo2Digit(params?.data?.valueData)} ${params?.data?.unit?.value ? params?.data?.unit?.value : ''}
      </span>
      </div>`;
      } else {
        return '';
      }
    }
    return sunburstTooltipConfig;
  }


  async generateChartDataForSunburst(chartObj: any) {
    let self =  this;
    const viewByEnumConvertMapping: { [key: string]: string } = viewByEnumConvert;
    this.transformedChartData = [];

    if (chartObj.chartConfig.viewType === chartViewType.COMBINE) {
      let allPointGraph: any = [];

      let listOfParameter = Object.keys(chartObj.chartData);

      if (listOfParameter?.length) {
        listOfParameter.forEach(async (parameterName: any, index: any) => {
          let parameterData: any = {
            'parameterName': parameterName,
            'showVisualisation': this.findVisualisationStatus(chartObj?.pointDefinitions, parameterName)
          }

          let graphConfig: any = {};

          graphConfig.tooltip = this.generateTooltipForSunburst(chartObj, parameterName);

          // graphConfig.label = GRAPH_CONFIGURATION.sunburstLabelConfig;

          //If chartType is COMBINE, generate series data for all sites

          try {
            let sunburstSeriesConfig: any = GRAPH_CONFIGURATION.sunburstSeriesConfig;
            if (chartObj.ViewByENUM == viewByEnumConvertMapping['SITE']) {
              sunburstSeriesConfig.radius = ['30%', '50%'];
            } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['CCU'] || chartObj.ViewByENUM == viewByEnumConvertMapping['SYSTEM']) {
              sunburstSeriesConfig.radius = ['30%', '65%'];
            } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['FLOOR']) {
              sunburstSeriesConfig.radius = ['30%', '90%'];
            } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['ZONE']) {
              sunburstSeriesConfig.radius = ['30%', '95%'];
            } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['ZONE EQUIP'] || chartObj.ViewByENUM == viewByEnumConvertMapping['EQUIP']) {
              sunburstSeriesConfig.radius = ['25%', '95%'];
            } else if(chartObj.ViewByENUM == viewByEnumConvertMapping['DEVICE']) {
              sunburstSeriesConfig.radius = ['20%', '95%'];
            }

            sunburstSeriesConfig.data = chartObj.chartData[parameterName];
            graphConfig.series = sunburstSeriesConfig;

            graphConfig.title = GRAPH_CONFIGURATION.sunburstLabelConfig;
            if (chartObj?.chartConfig?.[parameterName]?.chartLabel !== undefined) {
              graphConfig.title.text = chartObj?.chartConfig[parameterName]['chartLabel']
            } else {
              graphConfig.title.text = '';
            }

            parameterData['chartOptions'] = _.cloneDeep(graphConfig);
            parameterData['chartOptions']['siteName'] = 'All Sites';
            allPointGraph.push(parameterData);
          } catch (error) {
            console.error('Error generating series data for COMBINE view type', error);
          }

        });
      }
      this.transformedChartData.push(allPointGraph);
    } else {
      let listOfSites = chartObj.scopeSameAsDashboard || self.useDashBoardConfig ? self.dashboardService.dashboardConfig.siteRefs : chartObj.scopeSelectionData.siteRefs;
      if (listOfSites?.length) {
        try {
          const generateSeriesDataPromises = listOfSites.map(async (site: string) => {
            let allPointGraph: any = [];
            let listOfParameter = Object.keys(chartObj.chartData);

            if (listOfParameter?.length) {
              await Promise.all(
                listOfParameter.map(async (parameterName: any) => {
                  let parameterData: any = 
                  { 'parameterName': parameterName,
                    'showVisualisation': this.findVisualisationStatus(chartObj?.pointDefinitions, parameterName)
                  };

                  let graphConfig: any = {};
                  graphConfig.tooltip = this.generateTooltipForSunburst(chartObj, parameterName);

                  try {
                    let sunburstSeriesConfig: any = GRAPH_CONFIGURATION.sunburstSeriesConfig;
                    if (chartObj.ViewByENUM == viewByEnumConvertMapping['SITE']) {
                      sunburstSeriesConfig.radius = ['30%', '50%'];
                    } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['CCU'] || chartObj.ViewByENUM == viewByEnumConvertMapping['SYSTEM']) {
                      sunburstSeriesConfig.radius = ['30%', '65%'];
                    } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['FLOOR']) {
                      sunburstSeriesConfig.radius = ['30%', '90%'];
                    } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['ZONE']) {
                      sunburstSeriesConfig.radius = ['30%', '95%'];
                    } else if (chartObj.ViewByENUM == viewByEnumConvertMapping['ZONE EQUIP'] || chartObj.ViewByENUM == viewByEnumConvertMapping['EQUIP']) {
                      sunburstSeriesConfig.radius = ['25%', '95%'];
                    } else if(chartObj.ViewByENUM == viewByEnumConvertMapping['DEVICE']) {
                      sunburstSeriesConfig.radius = ['20%', '95%'];
                    }
                    let siteData = chartObj.chartData[parameterName].filter((data: any) => data.siteRef === site);

                    sunburstSeriesConfig.data = siteData;
                    parameterData['siteName'] = siteData[0]?.name;
                    graphConfig.series = sunburstSeriesConfig;
                    graphConfig.title = GRAPH_CONFIGURATION.sunburstLabelConfig;
                    if (chartObj?.chartConfig?.[parameterName]?.chartLabel !== undefined) {
                      graphConfig.title.text = chartObj?.chartConfig[parameterName]['chartLabel']
                    } else {
                      graphConfig.title.text = '';
                    }
                    parameterData['chartOptions'] = _.cloneDeep(graphConfig);
                    allPointGraph.push(parameterData);
                  } catch (error) {
                    console.error('Error generating series data for COMBINE view type', error);
                  }
                })
              );
            }
            this.transformedChartData.push(allPointGraph);
          });

          await Promise.all(generateSeriesDataPromises);
        } catch (error) {

          console.error('Error generating series data', error);
          // Show error message in UI if needed
        }
      }
    }

    this.transformedChartData = this.updateDataforLegends(this.transformedChartData);
    this.isLoaded = true;
    return this.transformedChartData;
  }

  async generateChartDataForGaugeChart(chartObj: any) {
    this.transformedChartData = [];
    if (chartObj.chartConfig.viewType === "COMBINE") {
      let allPointGraph: any = [];
      if (chartObj.data?.length) {
        chartObj.data.map(async (parameter: any) => {
          const parameterName = parameter.paramName;
          let parameterData: any = {
            'parameterName': parameterName,
            'showVisualisation': this.findVisualisationStatus(chartObj?.pointDefinitions, parameterName)
          }
          let graphConfig: any = {};
          try {
            let gaugeSeriesConfig: any = GRAPH_CONFIGURATION.gaugeSeriesConfig;
            gaugeSeriesConfig.startAngle = chartObj.chartConfig[parameterName]?.startAngle || 180;
            gaugeSeriesConfig.endAngle = chartObj.chartConfig[parameterName]?.endAngle || 0;
            gaugeSeriesConfig.min = chartObj.chartConfig[parameterName]?.minValue || 0;
            gaugeSeriesConfig.max = chartObj.chartConfig[parameterName]?.maxValue || 100;
            gaugeSeriesConfig.data = parameter?.data || [];
            gaugeSeriesConfig.axisLine.lineStyle.color = parameter?.colorArray || [];
            gaugeSeriesConfig.detail.formatter = function (value: any) {
              return `${Number.isInteger(value) ? value : value.toFixed(2)}${parameter?.unit ? parameter.unit : ''}`;
            }
            gaugeSeriesConfig.axisLabel = this.addDynamicLabelsForGaugeChart(parameter.conditionData, chartObj.chartConfig[parameterName]);
            graphConfig.series = gaugeSeriesConfig;
            parameterData['siteNames'] = parameter?.siteNames ?? [];
            parameterData['unit'] = parameter?.unit ? parameter?.unit : '';
            parameterData['chartOptions'] = _.cloneDeep(graphConfig);
            allPointGraph.push(parameterData);
          } catch (error) {
            console.error('Error generating series data for COMBINE view type', error);
          }
        });
      }
      this.transformedChartData.push(allPointGraph);
      this.isLoaded = true;
    } else {
      let listOfSites = chartObj.scopeSameAsDashboard || this.useDashBoardConfig ? this.dashboardService.dashboardConfig.siteRefs : (this.crudAction == CRUDOperationList.new) ? chartObj.scopeSelectionData.siteRefs: chartObj.widgetConfig.siteRefs;
      if (listOfSites?.length) {
        try {
          const siteParameterDataPromises = listOfSites.map(async (site:string) => {
            let siteParameters = [];
      
            if (chartObj.data?.length) {
              for (const parameter of chartObj.data) {
                const parameterName = parameter.paramName;
                let parameterData:any = {
                  parameterName: parameterName,
                  showVisualisation: this.findVisualisationStatus(chartObj?.pointDefinitions, parameterName),
                };
                const siteData = parameter.entityData.find((data:any) => data.siteRef === site);
                if (siteData) {
                  try {
                    let gaugeSeriesConfig = GRAPH_CONFIGURATION.gaugeSeriesConfig;
                    gaugeSeriesConfig.startAngle = chartObj.chartConfig[parameterName]?.startAngle || 180;
                    gaugeSeriesConfig.endAngle = chartObj.chartConfig[parameterName]?.endAngle || 0;
                    gaugeSeriesConfig.min = chartObj.chartConfig[parameterName]?.minValue || 0;
                    gaugeSeriesConfig.max = chartObj.chartConfig[parameterName]?.maxValue || 100;
                    gaugeSeriesConfig.data = siteData.data || [];
                    gaugeSeriesConfig.axisLine.lineStyle.color = siteData.colorArray || [];
                    gaugeSeriesConfig.detail = {
                      ...gaugeSeriesConfig.detail,
                      formatter: function (value: any) {
                        return `${Number.isInteger(value) ? value : value.toFixed(2)}${siteData?.unit?.value ? siteData?.unit?.value : ''}`;
                      }
                    } as any;
                    gaugeSeriesConfig.axisLabel = this.addDynamicLabelsForGaugeChart(siteData.conditionData, chartObj.chartConfig[parameterName]);
                    parameterData['siteName'] = siteData.siteName;
                    parameterData['unit'] = siteData?.unit?.value ? siteData?.unit?.value : '';
                    parameterData['chartOptions'] = _.cloneDeep({ series: gaugeSeriesConfig });
                    siteParameters.push(parameterData);
                  } catch (error) {
                    console.error(`Error generating series data for site ${site}`, error);
                  }
                }
              }
            }
            return siteParameters;
          });
          const allSiteData = await Promise.all(siteParameterDataPromises);
          this.transformedChartData = allSiteData;
          this.isLoaded = true;
        } catch (error) {
          this.isLoaded = true;
          console.error('Error generating gauge chart data', error);
        }
      }
    }

    this.transformedChartData = this.updateDataforLegends(this.transformedChartData);
    this.isLoaded = true;
    return this.transformedChartData;
  }

  updateDataforLegends(transformedChartData: any) {
    if (this.widgetObj?.builderWidgetPreview) {
      if (this.widgetObj?.selectedParam) {
        transformedChartData = transformedChartData.map((chartData: any) => {
          let chartWithData: any[] = [];
  
          if (!chartData.every((chartObj: any) => chartObj?.chartOptions?.series?.data?.length == 0)) {
            // Check if any chartObj does not have a siteName
            if (chartData.some((chartObj: any) => !chartObj?.siteName) && this.widgetObj.chartConfig.viewType === chartViewType.SEPARATE) {
              // Find a chartObj with a siteName
              const siteNameSource = chartData.find((chartObj: any) => chartObj?.siteName)?.siteName;
              if (siteNameSource) {
                // Assign the siteName to chartObjs without a siteName
                chartData.forEach((chartObj: any) => {
                  if (!chartObj.siteName) {
                    chartObj.siteName = siteNameSource;
                  }
                });
              }
            }
  
            chartWithData = chartData.filter((data: any) => 
              data.parameterName === this.widgetObj.selectedParam && data.showVisualisation
            );
          }
  
          return chartWithData;
        });
      }
    } else {
      transformedChartData = transformedChartData.map((chartData: any) => {
        let chartWithData: any[] = [];
  
        if (!chartData.every((chartObj: any) => chartObj?.chartOptions?.series?.data?.length == 0)) {
          // Check if any chartObj does not have a siteName
          if (chartData.some((chartObj: any) => !chartObj?.siteName) && this.widgetObj.chartConfig.viewType === chartViewType.SEPARATE) {
            // Find a chartObj with a siteName
            const siteNameSource = chartData.find((chartObj: any) => chartObj?.siteName)?.siteName;
            if (siteNameSource) {
              // Assign the siteName to chartObjs without a siteName
              chartData.forEach((chartObj: any) => {
                if (!chartObj.siteName) {
                  chartObj.siteName = siteNameSource;
                }
              });
            }
          }
          chartWithData = chartData.filter((data: any) => data.showVisualisation);
        }
  
        return chartWithData;
      });
    }
  
    transformedChartData.forEach((chartDataObj: any) => {
      if (chartDataObj?.length) {
        chartDataObj[0].selectedChartData = ObjectUtil.deepClone(chartDataObj[0].chartOptions);
        chartDataObj[0].selectedParameterName = chartDataObj[0].parameterName;
        this.isParamsDataLoaded = true;
      }
    });
    return transformedChartData;
  }

  addDynamicLabelsForGaugeChart(conditionData: any, chartConfig: any) {
    const { minValue, maxValue } = chartConfig;
    const splitNumber = 10;
    const tickInterval = (maxValue - minValue) / splitNumber;

    let gaugeAxisLabelConfig: any = GRAPH_CONFIGURATION.gaugeSeriesConfig.axisLabel;
    gaugeAxisLabelConfig.formatter = function (value: any) {
      const getNearestTick = (mid: number) => {
        return Math.round(mid / tickInterval) * tickInterval;
      };

      for (const condition of conditionData) {
        const mid = (condition.start + condition.end) / 2;
        const nearestTick = getNearestTick(mid);

        if (Math.abs(value - nearestTick) < tickInterval / 2) {
          return condition.label;
        }
      }
      return '';
    };
    return gaugeAxisLabelConfig;
  }

  //Method to find the visualisation status for a parameter
  findVisualisationStatus(pointDefinitions: any, parameterName: any) {
    return pointDefinitions.find((point: any) => point.name === parameterName)?.showVisualisation;
  }

  //Based on name return the formatted tooltip label.name for every entity will be unique
  findTooltipByNameForPieDoughnut(data: any[], input: string) {
    for (const index of data) {
      const match = index?.entityData?.find((entity: any) => entity?.name === input);
      if (match) {
        return match;
      }
    }
    return '';
  };

  seperateChartForEachParam(chartTypeName: any) {
    if (chartTypeName === chartType.SUNBURST || chartTypeName === chartType.TERRAIN || chartTypeName === chartType.GAUGE) {
      return true;
    }
    return false;
  }

  //Method to generate tooltip for pie and doughnut chart
  generateTooltipForPieDoughnut(chartData: any) {
    chartData?.data?.forEach((data: any) => {
      data?.entityData?.forEach((entity: any) => {
        if(entity?.aggregationMethod != 'COUNT') {
          entity.value = Number(entity?.value?.toFixed(2));
        }
      })
    })
    let graphConfig: any = GRAPH_CONFIGURATION.toolTipTriggerConfigForPieChart;
    let self = this;
  
    graphConfig.formatter = function (params: any) {
      const { name, value, percent, color } = params;
      const toolipNameandUnit = chartData?.data
        ? self.findTooltipByNameForPieDoughnut(chartData.data, name)
        : name;
  
      return `
        <div>
          <span style="color: ${color};">${toolipNameandUnit?.formattedTooltipLabel} : ${value} ${toolipNameandUnit?.unit?.value ? toolipNameandUnit?.unit?.value : ''} (${percent}%)</span>
        </div>
      `;
    };
  
    return graphConfig;
  }

  /**
   * Generates a CSS linear gradient string.
   *
   * @param stopColor0 - The color at the start (0%) of the gradient.
   * @param stopColor100 - The color at the end (100%) of the gradient.
   * @returns A string representing the CSS linear gradient.
   */
  linearGradient(stopColor0: string, stopColor100: string) {
    return `linear-gradient(to right, ${stopColor0} , ${stopColor100})`;
  }

  linearGradientForGaugeChart(pointdefinitions: any, parameterConfig: any, parameterName: string) {
    let colorArray: any = [];
    let conditions = pointdefinitions.find((point: any) => point.name === parameterName)?.conditions;
    if (conditions?.length) {
      colorArray = conditions.map((condition: any) => condition.color);
    } else {
      colorArray = [parameterConfig?.defaultColor];
    }
    const stops = colorArray.map((color: any, index: number) => {
      return `${color} ${(index / colorArray.length) * 100}% ${((index + 1) / colorArray.length) * 100}%`;
    }).join(', ');
    return `linear-gradient(to right, ${stops})`;
  }

  /**
   * Loads the selected parameter chart data into the first element of the transformed chart data array.
   *
   * @param {number} chartOptionIndex - The index of the chart option in the transformed chart data array.
   * @param {number} chartIndex - The index of the chart within the chart options.
   * @param {any} chartOption - The chart option object.
   */
  loadSelectedParameterChart(chartOptionIndex: number, chartIndex: number, chartOption: any) {
    this.isParamsDataLoaded = false;
    this.transformedChartData[chartOptionIndex][0].selectedChartData = ObjectUtil.deepClone(this.transformedChartData[chartOptionIndex][chartIndex].chartOptions);
    this.transformedChartData[chartOptionIndex][0].selectedParameterName = this.transformedChartData[chartOptionIndex][chartIndex].parameterName;
    setTimeout(() => {
      this.isParamsDataLoaded = true;
    }, 50);
  
  }
}
