import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WidgetLayoutService } from 'src/app/shared/_services/widget-layout.service';

@Component({
  selector: 'app-terrainchart',
  templateUrl: './terrainchart.component.html',
  styleUrls: ['./terrainchart.component.scss']
})
export class TerrainchartComponent implements OnChanges {
  @Input() chartOptions: any;
  @Input() chartAxisPositions: any;

  constructor(public widgetLayoutService: WidgetLayoutService) { }

  onChartInit(chart: any) {
    this.widgetLayoutService.updateAxisLabelPosition(chart, this.chartOptions, this.chartAxisPositions, 'TERRAIN');
  }

  ngOnChanges(changes: SimpleChanges) {
    this.chartOptions = changes['chartOptions'].currentValue;
  }
}
